import React, { useState, useEffect } from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import TextInput from "../Forms/TextInput"
import BtnForms from "../Buttons/BtnForms"

import "./ProfileForm.scss"
import DateInput from "../Forms/DateInput"
import GenderSelection from "../Forms/GenderSelection"
import { useAuthenticatedApi } from "../../hooks/useApi"
import usePreferences from "../../hooks/usePreferences"
import { convertToDateFormat } from "../../helpers/Date"
import useForm from "../../hooks/useForm"
import { useTranslation } from "react-i18next"

import {
  checkValidPhone,
  getCountryCode,
  getFullPhoneNumber,
  stripCountryCode,
} from "../../helpers/Phone"
import "react-phone-input-2/lib/style.css"
import PhoneInput from "react-phone-input-2"
import CheckBox from "../Forms/CheckBox"

function ProfileForm() {
  const { updateMe, me, removeTermById } = useAuthenticatedApi()
  const { dateFormat } = usePreferences()
  const [success, setSuccess] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [countryCode, setCountryCode] = useState("be")
  const { t } = useTranslation()

  const accountUpdateSchema = Yup.object().shape({
    firstName: Yup.string().required(t("validation.firstNameRequired")),
    lastName: Yup.string().required(t("validation.lastNameRequired")),
    zipCode: Yup.string().min(4, t("validation.zipCodeWrong")),
    country: Yup.string().when("zipCode", {
      is: val => val !== undefined,
      then: Yup.string().required(t("validation.countryRequired")),
      otherwise: Yup.string().notRequired(),
    }),
    dateOfBirth: Yup.string()
      .min(10, t("validation.dateOfBirthValid"))
      .required(t("validation.dateOfBirthRequired")),
    gender: Yup.string()
      .oneOf(["male", "female", "other"], t("validation.genderRequired"))
      .required(t("validation.genderRequired")),
    phone: Yup.string().test(
      "Phone Test",
      t("validation.phoneRequired"),
      value => (phoneNumber.length > 0 ? checkValidPhone(phoneNumber) : true)
    ),
  })

  const { submit, isLoading } = useForm({
    onSubmit: updateMe,
    onSuccess: formSuccess,
    onFail: () => console.log("fail"),
  })

  useEffect(() => {
    if (me.phones ? me.phones.items.length > 0 : false) {
      setPhoneNumber(getFullPhoneNumber(me.phones.items[0].phone))
      //setCountryCode(getCountryCode(me.phones.items[0].phone))
    }
  }, [me])

  useEffect(() => {
    if (me.phones ? me.phones.items.length > 0 : false) {
      setCountryCode(getCountryCode(me.phones.items[0].phone))
    }
  }, [me])

  function updateAccount(values) {
    let terms = {
      items: [],
    }
    if (
      values.promo &&
      (me.terms
        ? !me.terms.items.find(
            term => term.id === process.env.THIRD_PARTY_OPT_IN
          )
        : false)
    ) {
      terms.items.push({
        id: process.env.THIRD_PARTY_OPT_IN,
        source: "website",
      })
    }
    if (
      values.newsletter &&
      (me.terms
        ? !me.terms.items.find(term => term.id === process.env.NEWSLETTER_ID)
        : false)
    ) {
      terms.items.push({
        id: process.env.NEWSLETTER_ID,
        source: "website",
      })
    }
    if (!values.promo) {
      removeTermById(me.id, process.env.THIRD_PARTY_OPT_IN)
    }
    if (!values.newsletter) {
      removeTermById(me.id, process.env.NEWSLETTER_ID)
    }
    setSuccess(false)
    submit({
      ...values,
      ...{ terms: terms },
      date_of_birth: convertToDateFormat(values.dateOfBirth, dateFormat),
      ...{
        phones: checkValidPhone(phoneNumber)
          ? {
              items: [
                {
                  phone: phoneNumber,
                },
              ],
            }
          : null,
      },
      ...{
        addresses: values.zipCode
          ? {
              items: [
                {
                  zip_code: values.zipCode,
                  country: values.country,
                },
              ],
            }
          : null,
      },
    })
  }

  function formSuccess() {
    setSuccess(true)
  }

  return (
    <div className="profile">
      <Formik
        initialValues={{
          firstName: me ? me.firstName : "",
          lastName: me ? me.lastName : "",
          email: me ? me.email : "",
          phone: me
            ? me.phones
              ? me.phones.items.length > 0
                ? stripCountryCode(me.phones.items[0].phone)
                : ""
              : ""
            : "",
          zipCode: me
            ? me.addresses
              ? me.addresses.items.length > 0
                ? me.addresses.items[0].zipcode
                : ""
              : ""
            : "",
          country: "Belgium",
          dateOfBirth: me ? me.dateOfBirth : "",
          gender: me ? me.gender : "",
          newsletter: me
            ? me.terms
              ? !!me.terms.items.find(
                  term => term.id === process.env.NEWSLETTER_ID
                )
              : false
            : false,
          promo: me
            ? me.terms
              ? !!me.terms.items.find(
                  term => term.id === process.env.THIRD_PARTY_OPT_IN
                )
              : false
            : false,
        }}
        enableReinitialize
        validationSchema={accountUpdateSchema}
        onSubmit={values => updateAccount(values)}
      >
        {({ handleSubmit, errors, touched, values, setFieldValue }) => (
          <form className="form" onSubmit={handleSubmit}>
            <div className="form--fields">
              <TextInput name="firstName" type="text" placeholder="Voornaam" />
              <TextInput name="lastName" type="text" placeholder="Achternaam" />
              <TextInput
                name="email"
                type="email"
                disabled={true}
                placeholder="E-mailadres"
              ></TextInput>
              <TextInput
                name="zipCode"
                type="text"
                placeholder="Postcode"
              ></TextInput>

              <PhoneInput
                name="phone"
                value={phoneNumber}
                onChange={value => setPhoneNumber(value ? value : "")}
                placeholder="Telefoonnummer"
                country={countryCode}
              />
              {errors.phone ? (
                <div className="field--error">{errors.phone}</div>
              ) : null}
              <DateInput
                name="dateOfBirth"
                type="text"
                placeholder="Geboortedatum"
              />
              <GenderSelection name="gender" label="Geslacht" />
              <CheckBox name="newsletter" value="newsletter">
                {t("forms.newsletter")}
              </CheckBox>
              <CheckBox name="promo" value="promo">
                {t("forms.promo")}
              </CheckBox>
            </div>
            <div className="form--btns">
              <BtnForms large primary disabled={isLoading} loading={isLoading}>
                Wijzigingen opslaan
              </BtnForms>
              {success && (
                <p className="form--success">
                  Wijzigingen succesvol opgeslagen
                </p>
              )}
              {/*<BtnRounded large error disabled={isSubmitting}>Profiel
                verwijderen</BtnRounded>*/}
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default ProfileForm
