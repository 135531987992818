import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Nav from "../Nav/Nav"
import Footer from "../Footer/Footer"
import SearchModal from "../Modal/SearchModal"
import { AuthenticatedApiProvider } from "src/context/AuthenticatedApiContext"
import Auth from "../Auth/Auth"
import "./Container.scss"

export default function AuthenticatedContainer({
  children,
  page,
  shareImage,
  shareDescription,
}) {
  const [showLogin, setShowLogin] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const toggleLogin = () => setShowLogin(!showLogin)
  const toggleSearch = () => setShowSearch(!showSearch)

  return (
    <AuthenticatedApiProvider>
      <div className="nostalgie">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{page || ""} | Nostalgie</title>
        </Helmet>
        <Nav
          onPressSearch={toggleSearch}
          onPressLogin={toggleLogin}
          theme={page === "Home" ? "light" : "dark"}
        />
        <div className="page-content">{children}</div>
        <Footer />
        <SearchModal visible={showSearch} handleClose={toggleSearch} />
        <Auth visible={showLogin} close={toggleLogin} />
      </div>
    </AuthenticatedApiProvider>
  )
}
