import React, { useState } from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import BtnForms from "../Buttons/BtnForms"
import zxcvbn from "zxcvbn"

import "./ProfileForm.scss"
import { useAuthenticatedApi } from "../../hooks/useApi"
import useForm from "../../hooks/useForm"
import { useTranslation } from "react-i18next"
import PasswordStrengthCheck from "../Forms/PasswordStrength"

function ProfilePasswordForm() {
  const { updateMe } = useAuthenticatedApi()
  const { t } = useTranslation()
  const [success, setSuccess] = useState(false)

  const chooosePwSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, t("validation.passwordValid"))
      .required(t("validation.passwordRequired"))
      .test("score", t("validation.passwordStrength"), value => {
        let val
        value === undefined ? (val = "") : (val = value)
        return zxcvbn(val).score >= 3
      }),
  })

  const { submit, isLoading } = useForm({
    onSubmit: updateMe,
    onSuccess: formSuccess,
    onFail: () => console.log("fail"),
  })

  function updateAccount(values) {
    submit({
      password: {
        password: values.password,
      },
    })
  }

  function formSuccess() {
    setSuccess(true)
  }

  return (
    <div className="profile">
      <Formik
        initialValues={{
          password: "",
        }}
        enableReinitialize
        validationSchema={chooosePwSchema}
        onSubmit={values => updateAccount(values)}
      >
        {({ handleSubmit }) => (
          <form className="form" onSubmit={handleSubmit}>
            <div className="form--fields">
              <PasswordStrengthCheck
                name="password"
                type="password"
                placeholder={t("forms.newPassword")}
              />
            </div>
            <div className="form--btns">
              <BtnForms large primary loading={isLoading} disabled={isLoading}>
                {t("profilePasswordForm.action")}
              </BtnForms>
              {success && (
                <p className="form--success">
                  Wijzigingen succesvol opgeslagen
                </p>
              )}
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default ProfilePasswordForm
