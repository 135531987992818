import React, { useEffect, useState } from "react"
import "./ProfileForm.scss"
import Title from "../Text/Title"
import BtnRounded from "../Buttons/BtnRounded"
import { logoutRoute } from "../../routes/routes"
import ProfileForm from "./ProfileForm"
import { navigate } from "gatsby"
import ProfilePasswordForm from "./ProfilePasswordForm"
import { useTranslation } from "react-i18next"
import { useRedirect } from "../../hooks/useRedirect"

function Profile() {
  const [activeTab, setActiveTab] = useState("profile")
  const { t } = useTranslation()
  const { redirectToRoute, clearRedirectToRoute } = useRedirect();

  useEffect(() => {
    if(redirectToRoute === 'reset-password') {
      setActiveTab("password")
      clearRedirectToRoute();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="profile">
      <div className="profile__head">
        <Title>Mijn profiel</Title>
        <BtnRounded
          outline
          icon={<i className="icon-logout" />}
          onClick={() => navigate(logoutRoute)}
        >
          Uitloggen
        </BtnRounded>
      </div>
      <div className="auth__tabs">
        <button
          onClick={() => setActiveTab("profile")}
          className={`auth__tabs--item ${
            activeTab === "profile" ? "active" : ""
          }`}
        >
          {t("headerNav.Profile.personalData")}
        </button>
        <button
          onClick={() => setActiveTab("password")}
          className={`auth__tabs--item ${
            activeTab === "password" ? "active" : ""
          }`}
        >
          {t("profilePasswordForm.title")}
        </button>
      </div>
      {activeTab === "profile" && <ProfileForm />}
      {activeTab === "password" && <ProfilePasswordForm />}
    </div>
  )
}

export default Profile
