import { DateTime } from "luxon"
export const DATE_FORMAT = "yyyy-LL-dd"

export function convertToDateFormat(dateString, initialFormat) {
  if (typeof dateString === "undefined") {
    return undefined
  }

  return DateTime.fromFormat(dateString, initialFormat).toFormat(DATE_FORMAT)
}
