import React from "react"
import AuthenticatedContainer from "../components/Layout/AuthenticatedContainer"
import MainContent from "../components/Layout/MainContent"
import Profile from "../components/Profile/Profile"
import hero from "../resources/images/hero-profile.jpg"
import "./styles/Profile.scss"

function Profiel() {
  return (
    <AuthenticatedContainer>
      <MainContent isCompact imageInternal={hero}>
        <Profile />
      </MainContent>
    </AuthenticatedContainer>
  )
}

export default Profiel
